/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;
  /*========== Colors ==========*/
  --hue-color: 206;
  --black-color: hsl(var(--hue-color), 4%, 4%);
  --black-color-alt: hsl(0, 0%, 20%);
  --title-color: hsl(var(--hue-color), 4%, 95%);
  --text-color: hsl(var(--hue-color), 4%, 75%);
  --text-color-light: hsl(var(--hue-color), 4%, 65%);
  --white-color: #F00;
  --body-color: hsl(206, 4%, 6%);
  --container-color: hsl(206, 4%, 10%);
  --text-gradient: linear-gradient(#ff8a8a, #f00, #f00, #ac0000);
  --text-gradient-sigma: linear-gradient(#98ff7f, #209f00, #115700);
  --text-gradient-cafe: linear-gradient(#ddb66e, #4a2d26);
  --scroll-thumb-color: hsl(var(--hue-color), 4%, 16%);
  --scroll-thumb-color-alt: hsl(var(--hue-color), 4%, 20%);
  --navbar-gradient: linear-gradient(#181a1b, #181a1b00);
  /*========== Font and typography ==========*/
  --body-font: 'Montserrat', sans-serif;
  --biggest-font-size: 5rem;
  --bigger-font-size: 3.5rem;
  --big-font-size: 2.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;
  --text-line-height: 2rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /*========== Margenes Bottom ==========*/
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 7.5rem;
    --bigger-font-size: 4.5rem;
    --big-font-size: 4rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: default;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button,
input {
  border: none;
  outline: none;
}


img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4rem 0 2rem;
}

.section__title {
  font-size: var(--bigger-font-size);
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.section__title-gradient {
  background: var(--text-gradient);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.section__title-gradient-sigma {
  background: var(--text-gradient-sigma);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.section__title-gradient-cafe {
  background: var(--text-gradient-cafe);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

/*=============== LAYOUT ===============*/
.main {
  overflow: hidden;
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
}

/*=============== HEADER ===============*/
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background: transparent;
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  display: flex;
  width: 1.5rem;
}

.nav__toggle {
  font-size: 1.2rem;
  color: var(--white-color);

}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    background-color: var(--body-color);
    top: -100%;
    left: 0;
    width: 100%;
    padding: 4rem 0 3rem;
    transition: .2s;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.nav__link {
  color: var(--white-color);
  font-size: var(--h2-font-size);
  text-transform: uppercase;
  font-weight: var(--font-semi-bold);
  background: var(--text-gradient);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: .2s;
}

.nav__link-sigma {
  color: var(--white-color);
  font-size: var(--h2-font-size);
  text-transform: uppercase;
  font-weight: var(--font-semi-bold);
  background: var(--text-gradient-sigma);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: .2s;
}
.nav__link-cafe {
  color: var(--white-color);
  font-size: var(--h2-font-size);
  text-transform: uppercase;
  font-weight: var(--font-semi-bold);
  background: var(--text-gradient-cafe);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: .2s;
}

.nav__link:hover {
  background: var(--white-color);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.nav__link-sigma:hover {
  background: #209f00;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.nav__link-cafe:hover {
  background: #ddb66e;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.nav__close {
  position: absolute;
  font-size: 1.5rem;
  top: 1rem;
  right: 1rem;
  color: var(--white-color);

}

/* show menu */
.show-menu {
  top: 0;
}

/* Change background header */
.scroll-header {
  background-color: var(--body-color);
}

/* Active link */
.active-link {
  background: var(--white-color);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

/*=============== HOME ===============*/
.home__img {
  width: 250px;
  position: absolute;
  top: -16rem;
  right: 1.5rem;
}

.home__data {
  padding-top: 5rem;
}

.home__header {
  position: relative;
}

.home__title {
  position: absolute;
  top: -4rem;
  left: 1rem;
  line-height: 6rem;
  font-size: var(--biggest-font-size);
  background: var(--text-gradient);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.home__subtitle {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-2-5);
}

.home__title-description {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.home__description {
  margin-bottom: var(--mb-2-5);
  line-height: var(--text-line-height);
}

.home__price {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  margin-left: var(--mb-0-75);
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 1rem 1.25rem;
  border-radius: .5rem;
  transition: .3s;
}

.button:hover {
  background-color: var(--black-color-alt);
  box-shadow: rgba(0, 0, 0, 0.71) 0 0 15px;
}
.button-cafe {
  background-color: #2b1712;
}
.button-cafe:hover,
.button-cafe:active {
  background-color: #301a15;
}
.button-cafe:active {
  background-color: #140a08;
}

.button__icon {
  font-size: 1.2rem;
}

.button--flex {
  display: inline-flex;
  align-items: center;
  column-gap: .70rem;
}

/*=============== SPONSOR ===============*/
.sponsor__img {
  width: 90px;
}

.sponsor__container {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  row-gap: 5rem;
  justify-items: center;
  align-items: center;
}

/*=============== sigmadroid ===============*/
.sigmadroid__container {
  position: relative;
}

.sigmadroid__content {
  row-gap: 1.5rem;
}

.sigmadroid__data {
  display: grid;
  row-gap: .25rem;
}

.sigmadroid__icon {
  font-size: 1.2rem;
  color: var(--white-color);
}

.sigmadroid__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.sigmadroid__subtitle {
  font-size: var(--smaller-font-size);
}

.sigmadroid__data:nth-child(1), .sigmadroid__data:nth-child(4) {
  margin-left: 1.5rem;
}

.sigmadroid__img {
  display: contents;
  width: 250px;
  justify-items: center;
  top: 2rem;
}
.sigmadroid__img img{
  transition: transform 0.2s;
}
.sigmadroid__img img:hover {
  transform: scale(1.07);
  transition: transform 0.2s;
}

/*=============== watchface ===============*/
.watchface__container {
  position: relative;
  grid-template-columns: repeat(2, 1fr);
}

.watchface__data {
  padding: 5rem 0 3rem;
}

.watchface__img {
  width: 250px;
  position: absolute;
  left: -7rem;
}

.watchface__description {
  margin-bottom: var(--mb-1-5);
  line-height: var(--text-line-height);
}

/*=============== DISCOUNT ===============*/
.sig-but{
  position: static;

}

.discount__container {
  position: relative;
  background-color: var(--container-color);
  padding: 2rem 1.5rem;
  border-radius: .75rem;
}

.discount__title {
  color: white;
  position: relative;
  margin-left: 10px;
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-75);
  text-shadow: black 0px 0px 8px;
}

.discount__description {
  color: white;
  position: relative;
  margin-left: 20px;
  margin-bottom: var(--mb-1);
  column-width: 40vw;
  text-shadow: black 0px 0px 8px;
}

.discount__img {
  width: 40vw;
  position: absolute;
  top: 4rem;
  right: .7rem;
}

.discount__img1 {
  border-radius: 20%;
  margin-top: 9rem;
  margin-bottom: 10px;
  max-width: 7rem;
  position: absolute;
  margin-right: .7rem;
  right: 5px;
}

.discount__img2 {
  border-radius: 20%;
  margin-top: 1rem;
  margin-bottom: 10px;
  max-width: 7rem;
  position: absolute;
  margin-right: .7rem;
  right: 5px;
}
.discount__img3 {
  border-radius: 20%;
  margin-top: 9rem;
  margin-bottom: 10px;
  max-width: 7rem;
  position: absolute;
  margin-right: .7rem;
  right: 5px;
}
.discount__img4 {
  border-radius: 20%;
  margin-top: 1rem;
  margin-bottom: 10px;
  max-width: 7rem;
  position: absolute;
  margin-right: .7rem;
  right: 5px;
}
.discount__img1,
.discount__img2,
.discount__img3,
.discount__img4 {
  box-shadow: rgba(0, 0, 0, 0.71) 0 0 15px;
  transition: border-radius 0.2s;
  z-index: 99;
}
.discount__img1:hover,
.discount__img2:hover,
.discount__img3:hover,
.discount__img4:hover,
.discount__img1:active,
.discount__img2:active,
.discount__img3:active,
.discount__img4:active {
  border-radius: 50%;
  transition: border-radius 0.2s;
}

/*=============== PRODUCTS ===============*/
.products__line {
  line-height: 4rem;
}

.products__container {
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem 1.5rem;
  padding-top: 3rem;
}

.products__card {
  position: relative;
  height: 132px;
  background-color: var(--container-color);
  padding: .75rem;
  border-radius: .5rem;
  display: grid;
  transition: box-shadow 0.2s, background-color 0.2s;
}
.products__card:hover {
  background-color: var(--black-color-alt);
  box-shadow: rgba(0, 0, 0, 0.71) 0 0 15px;
  transition: box-shadow 0.2s, background-color 0.2s;
}

.products__img {
  width: 80px;
  position: absolute;
  inset: 0;
  margin-right: auto;
  margin-left: auto;
  top: -3rem;
}

.products__content {
  align-self: flex-end;
}

.products__title, .products__price {
  font-size: var(--small-font-size);
}

.products__price {
  font-weight: var(--font-semi-bold);
}

.products__button {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  padding: .5rem;
  border-radius: .35rem;
}

/*=============== FOOTER ===============*/
.footer__container {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 2rem;
}

.footer__logo {
  width: 2rem;
}

.footer__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.footer__links {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

.footer__link {
  color: var(--text-color);
}

.footer__link:hover {
  color: var(--white-color);
}

.footer__form {
  display: flex;
  column-gap: .5rem;
  background-color: var(--container-color);
  padding: .5rem .75rem;
  border-radius: .5rem;
  margin-bottom: var(--mb-2);
}

.footer__input {
  background-color: var(--container-color);
  width: 90%;
  color: var(--white-color);
}

.footer__input::placeholder {
  color: var(--text-color);
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

.footer__social {
  display: flex;
  column-gap: 1.25rem;
}

.footer__social-link {
  display: inline-flex;
  color: var(--white-color);
  background-color: var(--container-color);
  padding: .5rem;
  border-radius: .25rem;
  font-size: 1rem;
}

.footer__social-link:hover {
  background-color: var(--black-color);
}

.footer__copy {
  margin-top: 5rem;
  text-align: center;
}

.footer__copy-link {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.tg__link{
  width: 50vw;
  transition: .2s;
}

/*=============== SCROLL UP ===============*/
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  background-color: var(--container-color);
  border-radius: .25rem;
  padding: .45rem;
  opacity: 9;
  z-index: var(--z-tooltip);
  transition: .2s;
}

.scrollup:hover {
  background-color: var(--black-color);
  opacity: 1;
}

.scrollup__icon {
  color: var(--white-color);
  font-size: 1.35rem;
}

/* Show Scroll Up*/
.show-scroll {
  bottom: 5rem;
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: .60rem;
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-color-alt);
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
  .section__title {
    font-size: var(--big-font-size);
  }
  .home__img {
    width: 200px;
    top: -13rem;
  }
  .home__title {
    top: -4rem;
    font-size: var(--bigger-font-size);
  }
  .home__data {
    padding-top: 1rem;
  }
  .home__description {
    font-size: var(--small-font-size);
  }
  .sigmadroid__img {
    width: 200px;
  }
  .watchface__container {
    grid-template-columns: .6fr 1fr;
  }
  .watchface__img {
    width: 220px;
    top: -2rem;
    left: -9rem;
  }
  .watchface__data {
    padding: 0;
  }
  .products__container {
    grid-template-columns: 142px;
    justify-content: center;
  }

  .tg__link{
    width: 70vw;
    transition: .2s;
  }
  .discount__description {
    width: 40vw;
  }
}
/* For smaller medium devices */
@media screen and (min-width: 341px) and (max-width: 575px) {
  .discount__description {
    width: 40vw;
  }
  .discount__title {
    width: 40vw;
  }
}
/* For medium devices */
@media screen and (min-width: 576px) {
  .home__container {
    grid-template-columns: .8fr 1fr;
  }
  .home__data {
    padding-top: 2rem;
  }
  .home__img {
    top: -7rem;
    left: 0;
  }
  .sigmadroid__img {
    position: initial;
  }
  .sigmadroid__container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }
  .watchface__img {
    position: initial;
  }
  .watchface__data {
    padding: 0;
  }
  .watchface__container {
    grid-template-columns: max-content 250px;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
  }
  .discount__img {
    position: initial;
  }
  .discount__img1 {
    border-radius: 20%;
    top: 8rem;
    margin-bottom: auto;
    max-width: 12rem;
    position: absolute;
    right: 5rem;
  }
  
  .discount__img2 {
    border-radius: 20%;
    top: 2rem;
    margin-bottom: auto;
    max-width: 12rem;
    position: absolute;
    right: 5rem;
  }

  .discount__img3 {
    border-radius: 20%;
    top: 8rem;
    margin-bottom: auto;
    max-width: 12rem;
    position: absolute;
    right: 5rem;
  }
  
  .discount__img4 {
    border-radius: 20%;
    top: 2rem;
    margin-bottom: auto;
    max-width: 12rem;
    position: absolute;
    right: 5rem;
  }
  .discount__container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }
  .products__container {
    grid-template-columns: repeat(3, 142px);
    justify-content: center;
  }

  .tg__link{
    width: 40vw;
    transition: .2s;
  }
  .discount__description {
    position: relative;
    margin-left: 20px;
    margin-bottom: var(--mb-1);
    column-width: 40vw;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }
  .section {
    padding: 6rem 0 2rem;
  }
  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__logo {
    width: 2rem;
  }
  .nav__list {
    flex-direction: row;
    column-gap: 3.5rem;
  }
  .nav__link {
    font-size: var(--normal-font-size);
    text-transform: initial;
  }
  .nav__link-sigma {
    font-size: var(--normal-font-size);
    text-transform: initial;
  }
  .nav__link-cafe {
    font-size: var(--normal-font-size);
    text-transform: initial;
  }
  .nav__toggle, .nav__close {
    display: none;
  }
  .home__container {
    position: relative;
    grid-template-columns: repeat(2, 1fr);
  }
  .home__img {
    top: -9rem;
    left: 4rem;
  }
  .home__data {
    padding-top: 8rem;
  }
  .sigmadroid__img {
    width: 300px;
  }
  .watchface__container {
    column-gap: 5rem;
  }
  .watchface__img {
    width: 300px;
  }
  .watchface__description {
    margin-bottom: var(--mb-2);
  }
  .discount__container {
    grid-template-columns: 250px max-content;
    justify-content: center;
    column-gap: 5rem;
    padding: 3rem 0;
  }
  .discount__title {
    left: -10rem;
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-1);
  }
  .discount__description {
    left: -10rem;
    margin-bottom: var(--mb-2);
    position: relative;
    column-width: 40vw;
    }

  .products__container {
    grid-template-columns: repeat(3, 162px);
    gap: 6rem 3rem;
    padding-top: 5rem;
  }
  .products__card {
    height: 152px;
    padding: .85rem;
  }
  .products__img {
    width: 95px;
  }
  .footer__container {
    grid-template-columns: .4fr .7fr .7fr 1fr;
  }

  .tg__link{
    width: 30vw;
    transition: .2s;
  }
  .sig-but{
    position: relative;
    left: -10rem;
  }
  .cafeenvivo__text{
    left: 0;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .home__img {
    width: 300px;
    top: -15rem;
  }
  .home__title {
    top: -5rem;
    left: 3.5rem;
  }
  .home__description {
    padding-right: 5rem;
  }
  .sponsor__img {
    width: 100px;
  }
  .discount__img {
    width: 350px;
  }
  .discount__img1 {
    width: 250px;
  }
  .discount__img2 {
    width: 250px;
  }
  .discount__img3 {
    width: 250px;
  }
  .discount__img4 {
    width: 250px;
  }
  .footer__container {
    padding-top: 3rem;
  }
  .footer__copy {
    margin-top: 9rem;
  }

  .tg__link{
    width: 15vw;
    transition: .2s;
  }
  .discount__description {
    position: relative;
    margin-left: 20px;
    margin-bottom: var(--mb-1);
    column-width: 40vw;
  }
}



.lang{
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
}

.lang img{
  margin: 2px;
}

.weinachten{
  background-color: #c70000;
  background-image: url(assets/img/snow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.apkbutton{
  width: 200px;
  height: 70px;
  margin-top: 10px;
}

.sigma-button{
  display: flex;
  text-align: center;
  height: 55px;
  margin-top: 15px;
  background-color: #209f00;
  color: white;
  justify-content: center;
}
.sigma-button:hover{
  margin-top: 10px;
  background-color: #177400;
  color: white;
}
.sigma-button:active{
  margin-top: 10px;
  background-color: rgb(72, 0, 0);
  color: white;
}